import { memo, useEffect, useState } from 'react'
// import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AssetSummarySkeleton from './skeleton/asset_summary_skeleton'
import CanvasJSReact from '@canvasjs/react-charts'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze } from '@syncfusion/ej2-react-grids'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

const AccountSummary = (props) => {
	const { account_summary, best_practices_stats, getAccountAssetSummary, showTab, default_account } = props
	const [isPageLoading, setIsPageLoading] = useState(true)

	useEffect(() => {
		if(default_account) {
			// setIsPageLoading(true)

			const account_id = default_account.id

			getAccountAssetSummary( { account_id }).then(response => {
				setIsPageLoading(false)
			}).catch(err => setIsPageLoading(false))
		}
	}, [getAccountAssetSummary, default_account])

	const flagCallColumn = (value) => {
		return value.calls > 0 ? 'Yes' : 'No'
	}

	const flagLeadFormColumn = (value) => {
		return value.lead_form > 0 ? 'Yes' : 'No'
	}

	const flagLocationColumn = (value) => {
		return value.location > 0 ? 'Yes' : 'No'
	}

	const flagPriceColumn = (value) => {
		return value.price > 0 ? 'Yes' : 'No'
	}

	const flagAppColumn = (value) => {
		return value.mobile_app > 0 ? 'Yes' : 'No'
	}

	const flagPromotionColumn = (value) => {
		return value.promotion > 0 ? 'Yes' : 'No'
	}

	const flagBusinessLogoColumn = (value) => {
		return value.business_logo > 0 ? 'Yes' : 'No'
	}

	const flagBusinessNameColumn = (value) => {
		return value.business_name > 0 ? 'Yes' : 'No'
	}

	const getChartOptions = () => {

		const best_practices_ads = best_practices_stats['best_practices_ads']
		const ideal_practices_ads = best_practices_stats['ideal_practices_ads']
		const lackof_best_practices_ads = best_practices_stats['lackof_best_practices_ads']
		return {

			animationEnabled: false,
			exportEnabled: false,
			// theme: "dark2", // "light1", "dark1", "dark2"
			title:{
				text: "Best Practices Ads"
			},
			data: [{
				type: "pie",
				indexLabel: "{label}: {y} campaigns",		
				startAngle: -90,
				dataPoints: [
					{ y: best_practices_ads, label: "Best Practices", color: "#17a2b8" },
					{ y: ideal_practices_ads, label: "Ideal Practices", color: "#28a745" },
					{ y: lackof_best_practices_ads, label: "Lack of Best Practices", color: "#dc3545" },
				]
			}]
		}
	}

	const navigateCampaign = (ev) => {
		ev.preventDefault()
		showTab('campaign')
	}

	const showAccountName = (value) => {
		return (
			<>
				<a href="/#" onClick={navigateCampaign}>{value.customer_name}</a>
			</>
		)
	}

	return (
		<>
			{!isPageLoading &&
				<>
					{Object.keys(best_practices_stats).length > 0 &&
						<Row mb={2}>
							<Col md={12}>
								<CanvasJSChart options = { getChartOptions() } />
							</Col>
						</Row>
					}
					
					{account_summary.length > 0 &&
						<>
							<Row className="mb-2">
								<Col md={12}>
									<div className='control-pane'>
										<div className='control-section'>
											<GridComponent dataSource={account_summary}  frozenColumns={1}>
												<ColumnsDirective>
									            	<ColumnDirective field='customer_name' headerText='Account' template={showAccountName} />
									            	<ColumnDirective field='ads' width='100' headerText='Active Ads' />
									            	<ColumnDirective field='policy_error_ads' width='120' headerText='Policy Issues' />
									            	<ColumnDirective field='sitelinks' width='100' headerText='Sitelinks' />
									            	<ColumnDirective field='sitelinks_descriptions' width='120' headerText='Sitelinks Desc' />
									            	<ColumnDirective field='callout' width='100' headerText='Callouts' />
									            	<ColumnDirective field='structured_snippet' width='150' headerText='Structured Snippet' />
									            	<ColumnDirective field='image' width='100' headerText='Image' />
									            	<ColumnDirective field='headline' width='100' headerText='Headline' />
									            	<ColumnDirective field='description' width='110' headerText='Description' />
									            	<ColumnDirective field='calls' width='100' headerText='Call' template={flagCallColumn} />
									            	<ColumnDirective field='lead_form' width='100' headerText='Lead Form' template={flagLeadFormColumn} />
									            	<ColumnDirective field='location' width='100' headerText='Location' template={flagLocationColumn} />
									            	<ColumnDirective field='price' width='100' headerText='Price' template={flagPriceColumn} />
									            	<ColumnDirective field='mobile_app' width='100' headerText='App' template={flagAppColumn} />
									            	<ColumnDirective field='promotion' width='100' headerText='Promotion' template={flagPromotionColumn} />
									            	<ColumnDirective field='business_logo' width='130' headerText='Business Logo' template={flagBusinessLogoColumn} />
									            	<ColumnDirective field='business_name' width='130' headerText='Business Name' template={flagBusinessNameColumn} />
									        	</ColumnsDirective>
									        	<Inject services={[Freeze]}/>
											</GridComponent>
										</div>
									</div>
								</Col>
							</Row>
						</>
					}
				</>
			}

			{isPageLoading &&
				<>
					<AssetSummarySkeleton />
				</>
			}
		</>
	)
}

export default memo(AccountSummary)
