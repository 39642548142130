const initialState = {
	non_bidding_keywords: {'result': [], count: 0},
	dashboard_stats: {}
}

const dashboardReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_DASHBOARD_DATA':

			return {
				...state,
				dashboard_stats: {...state.dashboard_stats, ...action.payload.dashboard_stats},
			}

		case 'UPDATE_NON_BIDDING_KEYWORDS':
			return {
				...state,
				non_bidding_keywords: {...state.non_bidding_keywords, 'result': action.payload.result.data, 'count': action.payload.result.pagination.total_count},
			}

		default:
			return { ...state }

	}
}

export default dashboardReducer