import { connect } from 'react-redux'
// import CampaignSummary from 'pages/assets/campaign_summary'
import CampaignSummary from 'pages/assets/components/campaign_summary'
import { getCampaignAssetSummary } from 'actions'

const mapStateToProps = (state) => {
   return {
      default_account: state.reducer.default_account,
      campaign_summary_data: state.assetReducer.campaign_summary_data,
      best_practices_stats: state.assetReducer.best_practices_stats,
      selected_campaigns: state.assetReducer.selected_campaigns,
   }
}

const mapActionsToProps = {
   getCampaignAssetSummary,
}

export default connect(mapStateToProps, mapActionsToProps)(CampaignSummary)