import { connect } from 'react-redux'
// import AccountSummary from 'pages/assets/account_summary'
import AccountSummary from 'pages/assets/components/account_summary'
import { getAccountAssetSummary } from 'actions'

const mapStateToProps = (state) => {
   return {
      default_account: state.reducer.default_account,
      account_summary: state.assetReducer.account_summary,
      best_practices_stats: state.assetReducer.best_practices_stats,
   }
}

const mapActionsToProps = {
   getAccountAssetSummary,
}

export default connect(mapStateToProps, mapActionsToProps)(AccountSummary)