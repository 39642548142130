import { memo } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Placeholder from 'react-bootstrap/Placeholder'

const NonBiddingKeywordsSkeleton = (props) => {

	return (
		<>
			<Row className="mb-3">
				<Col md={12}>
					<Placeholder as="h4" className="m-3" animation="glow">
						<Placeholder xs={3} />
					</Placeholder>
					
					<Placeholder  animation="glow">
						{Array.from({ length: 10 }, (_, index) =>
							<Placeholder key={index} as="p" className="m-3" animation="glow">
								<Placeholder xs={12} />
							</Placeholder>
						)}
					</Placeholder>
				</Col>
			</Row>
		</>
	)
}

export default memo(NonBiddingKeywordsSkeleton)