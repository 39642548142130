import axios from 'axios'

class GoogleAuthService {

	getAuthCredentials() {
		return new Promise((resolve, reject) => {
			axios.get(`/api/google_auth/credentials/`).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	generateGoogleAuthTokens(payload) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/google_auth/generate_tokens/`, {
				params: { ...payload }
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	getGoogleAccounts() {
		return new Promise((resolve, reject) => {
			axios.get(`/api/google_auth/accounts/`).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	fetchGoogleAccounts(params) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/google_auth/fetch_google_accounts/`, {
				params: { ...params }
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	addGoogleAccounts(payload) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/google_auth/add_google_accounts/`, payload).then(response => {
				resolve(response)
			}).catch(error => {
				// console.log(error)
				reject(error)
			})
		})
	}

	deleteGoogleAccount(id) {
		return new Promise((resolve, reject) => {
			axios.delete(`/api/google_auth/accounts/${id}/delete/`).then(response => {
				resolve(response)
			}).catch(error => {
				// console.log(error)
				reject(error)
			})
		})
	}

	makeDefaultGoogleAccount(id) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/google_auth/accounts/${id}/make_default/`).then(response => {
				resolve(response)
			}).catch(error => {
				// console.log(error)
				reject(error)
			})
		})
	}

}
const googleAuthService = new GoogleAuthService()

export default googleAuthService