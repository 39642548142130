import GoogleAuthService from 'services/googleAuthService'

const getAuthCredentials = () => async(dispatch) => {
	try {
      
      const response = await GoogleAuthService.getAuthCredentials()
      
      if(response.data.success) {
         dispatch({
            type: 'UPDATE_OAUTH_SETTING',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      // console.log(err)
   }  
}

const generateGoogleAuthTokens = (payload) => async(dispatch) => {
   try {
      
      return await GoogleAuthService.generateGoogleAuthTokens(payload)

   } catch(err) {
      // console.log(err)
   }  
}

const getGoogleAccounts = () => async(dispatch) => {
   try {
      
      const response = await GoogleAuthService.getGoogleAccounts()
      
      if(response.data.success) {
         dispatch({
            type: 'UPDATE_GOOGLE_ACCOUNTS',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      // console.log(err)
   }  
}

const fetchGoogleAccounts = (payload) => async(dispatch) => {
   try {
      
      return await GoogleAuthService.fetchGoogleAccounts(payload)

   } catch(err) {
      // console.log(err)
   }  
}

const addGoogleAccounts = (payload) => async(dispatch) => {
   try {
      
      const response = await GoogleAuthService.addGoogleAccounts(payload)
      return response

   } catch(err) {
      console.log(err)
   } 
}

const deleteGoogleAccount = (id) => async(dispatch) => {
   try {
      
      const response = await GoogleAuthService.deleteGoogleAccount(id)
      if(response.data.success) {
         dispatch({
            type: 'DELETE_GOOGLE_ACCOUNT',
            payload: { id },
         })
      }
      return response

   } catch(err) {
      console.log(err)
   } 
}

const makeDefaultGoogleAccount = (id) => async(dispatch) => {
   try {
      
      const response = await GoogleAuthService.makeDefaultGoogleAccount(id)
      if(response.data.success) {
         dispatch({
            type: 'UPDATE_GOOGLE_ACCOUNTS',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   } 
}

export {
	getAuthCredentials,
   generateGoogleAuthTokens,
   getGoogleAccounts,
   fetchGoogleAccounts,
   addGoogleAccounts,
   deleteGoogleAccount,
   makeDefaultGoogleAccount,
}
