import { memo } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Placeholder from 'react-bootstrap/Placeholder'

const DashboardSkeleton = (props) => {

	return (
		<>
			<div className="row">

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1"><Placeholder as="p" animation="glow"><Placeholder xs={12}  /></Placeholder></div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"><Placeholder as="p" animation="glow"><Placeholder xs={3} /></Placeholder></div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1"><Placeholder as="p" animation="glow"><Placeholder xs={12}  /></Placeholder></div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"><Placeholder as="p" animation="glow"><Placeholder xs={3} /></Placeholder></div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1"><Placeholder as="p" animation="glow"><Placeholder xs={12}  /></Placeholder></div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"><Placeholder as="p" animation="glow"><Placeholder xs={3} /></Placeholder></div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1"><Placeholder as="p" animation="glow"><Placeholder xs={12}  /></Placeholder></div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"><Placeholder as="p" animation="glow"><Placeholder xs={3} /></Placeholder></div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			
			<Row className="mb-3">
				<Col md={12}>
					<Placeholder  animation="glow">
						{Array.from({ length: 10 }, (_, index) =>
							<Placeholder key={index} as="p" className="m-3" animation="glow">
								<Placeholder xs={12} />
							</Placeholder>
						)}
					</Placeholder>
				</Col>
			</Row>
		</>
	)
}

export default memo(DashboardSkeleton)