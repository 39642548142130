import { memo } from 'react'
import Placeholder from 'react-bootstrap/Placeholder'

const AssetSummarySkeleton = (props) => {

	return (
		<>
			{Array.from({ length: 100 }, (_, index) =>
				<Placeholder key={index} as="p" animation="glow">
					<Placeholder xs={12} />
				</Placeholder>
			)}
		</>
	)
}

export default memo(AssetSummarySkeleton)