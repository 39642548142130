const initialState = {
	negative_keywords: [],
	negative_keywords_data: {'result': [], count: 0}
}

const searchTermReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_NEGATIVE_KEYWORDS':

			return {
				...state,
				negative_keywords: [...action.payload.result.data],
			}

		case 'UPDATE_NEGATIVE_KEYWORDS_DATA_OBJ':

			return {
				...state,
				negative_keywords_data: {...state.negative_keywords_data, 'result': action.payload.result.data, 'count': action.payload.result.pagination.total_count},
			}

		default:
			return { ...state }

	}
}

export default searchTermReducer