import { connect } from 'react-redux'
import NegativeKeywords from 'pages/search_terms/negative_keywords'
import { getNegativeKeywords } from 'actions'

const mapStateToProps = (state) => {
   return {
      default_account: state.reducer.default_account,
      ...state.searchTermReducer,
   };
};

export default connect(mapStateToProps, { getNegativeKeywords })(NegativeKeywords)