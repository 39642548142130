const initialState = {
	account_summary: [],
	campaign_summary: [],
	ad_group_summary: [],
	ad_summary: [],
	best_practices_stats: {},
	selected_campaigns: [],
	selected_adgroups: [],
	campaign_summary_data: {'result': [], count: 0},
	ad_group_summary_data: {'result': [], count: 0},
	ad_summary_data: {'result': [], count: 0},

}

const assetReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_ACCOUNT_SUMMARY':

			return {
				...state,
				account_summary: [...action.payload.response.data],
				best_practices_stats: { ...action.payload.best_practices_stats },
			}

		case 'UPDATE_CAMPAIGN_SUMMARY':

			return {
				...state,
				campaign_summary: [...action.payload.response.data],
				best_practices_stats: { ...action.payload.best_practices_stats },
			}

		case 'UPDATE_AD_GROUP_SUMMARY':

			return {
				...state,
				ad_group_summary: [...action.payload.response.data],
				best_practices_stats: { ...action.payload.best_practices_stats },
			}

		case 'UPDATE_AD_SUMMARY':

			return {
				...state,
				ad_summary: [...action.payload.response.data],
				best_practices_stats: { ...action.payload.best_practices_stats },
			}

		case 'UPDATE_SELECTED_CAMPAIGNS':
			// console.log(action.payload.value)
			return {
				...state,
				selected_campaigns: action.payload.value,
			}

		case 'UPDATE_SELECTED_ADGROUPS':

			return {
				...state,
				selected_adgroups: action.payload.value,
			}

		case 'UPDATE_CAMPAIGN_ASSETS_DATA_OBJ':
			return {
				...state,
				campaign_summary_data: {...state.campaign_summary_data, 'result': action.payload.response.data, 'count': action.payload.response.pagination.total_count},
			}

		case 'UPDATE_AD_GROUP_ASSETS_DATA_OBJ':
			return {
				...state,
				ad_group_summary_data: {...state.ad_group_summary_data, 'result': action.payload.response.data, 'count': action.payload.response.pagination.total_count},
			}

		case 'UPDATE_AD_ASSETS_DATA_OBJ':
			return {
				...state,
				ad_summary_data: {...state.ad_summary_data, 'result': action.payload.response.data, 'count': action.payload.response.pagination.total_count},
			}


		default:
			return { ...state }

	}
}

export default assetReducer