import SearchTermService from 'services/searchTermService'

const getNegativeKeywords = (params) => async(dispatch) => {
	try {
      
      const response = await SearchTermService.getNegativeKeywords(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_NEGATIVE_KEYWORDS',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

export {
	getNegativeKeywords,
}
