import axios from 'axios'

class DashboardService {

	getDashboardData(params) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/dashboard/`, {
				params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	getNonBiddingKeywords(params) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/searchterms/non_bidding_keywords/`, {
				...params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

}
const dashboardService = new DashboardService()

export default dashboardService