import { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { PaginationControl } from 'react-bootstrap-pagination-control'
import AssetSummarySkeleton from './skeleton/asset_summary_skeleton'
import CanvasJSReact from '@canvasjs/react-charts'
import Select from 'react-select'
// import Alert from 'react-bootstrap/Alert'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort, Filter } from '@syncfusion/ej2-react-grids'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

const AdSummary = (props) => {
	let gridInstance;
	const { ad_summary_data, best_practices_stats, getAdAssetSummary, selected_campaigns, selected_adgroups, fetchCampaignAdgroups, default_account } = props
	const [isPageLoading, setIsPageLoading] = useState(true)
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [selectedCampaigns, setSelectedCampaigns] = useState(selected_campaigns)
	const [selectedAdgroups, setSelectedAdgroups] = useState(selected_adgroups)
	const [selectedRating, setSelectedRating] = useState(null)
	const [campaigns, setCampaigns] = useState([])
	const [adgroups, setAdgroups] = useState([])
	const [sortBy, setSortBy] = useState(null)
	const [sortDirection, setSortDirection] = useState(null)
	const [gridKey, setGridKey] = useState(Date.now())
	const ratingOptions = [
		{
			name: 'Average',
			'value': 'AVERAGE'
		},
		{
			name: 'Excellent',
			'value': 'EXCELLENT'
		},
		{
			name: 'Good',
			'value': 'GOOD'
		},
		{
			name: 'Poor',
			'value': 'POOR'
		},

	]
	const filterSettings: any = { 
		type: 'Menu',
		operators: {
			stringOperator: [
				{ value: 'startswith', text: 'Starts With' },
				{ value: 'endswith', text: 'Ends With' },
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'contains', text: 'Contains' },
		 		{ value: 'doesnotcontain', text: 'Does Not Contain' },
			],
			numberOperator: [
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'greaterthan', text: 'Greater Than' },
		 		{ value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
		 		{ value: 'lessthan', text: 'Less Than' },
		 		{ value: 'lessthanorequal', text: 'Less Than Or Equal' },
			],
		}
	}
	const [filterColumns, setFilterColumns] = useState([])

	const fetchAdAssetSummaryData = useCallback(() => {
		// setIsPageLoading(true)
		let campaign_ids = null
		let adgroup_ids = null
		if(selected_campaigns.length) {
			campaign_ids = selected_campaigns.map(item => item.campaign_id).join(',')
		}

		if(selected_adgroups.length) {
			adgroup_ids = selected_adgroups.map(item => item.adgroup_id).join(',')
		}

		const selected_rating = selectedRating
		const account_id = default_account.id
		const sort_by = sortBy
		const sort_direction = sortDirection
		const filters = filterColumns

		getAdAssetSummary({ page, account_id, campaign_ids, adgroup_ids, sort_by, sort_direction, selected_rating, filters }).then(response => {
			setIsPageLoading(false)
			if(response.data.success) {
				setTotalRows(response.data.response.pagination.total_count)
				setCampaigns(response.data.campaigns)
				setAdgroups(response.data.adgroups)
				dispatch({
					type: 'UPDATE_AD_ASSETS_DATA_OBJ',
					payload: response.data,
				})
				setGridKey(Date.now())
			}

		}).catch(err => setIsPageLoading(false))
	}, [getAdAssetSummary, page, selected_campaigns, selected_adgroups, sortBy, sortDirection, filterColumns, default_account, selectedRating, dispatch])

	useEffect(() => {
		if(default_account) {
			fetchAdAssetSummaryData()
		}
	}, [fetchAdAssetSummaryData, default_account])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = ad_summary_data
		}
	}, [gridInstance, ad_summary_data, gridKey])

	const dataStateChange = (args) => {
		if(args.action.requestType === 'sorting') {
			if(args.action.columnName && args.action.direction) {
				setSortBy(args.action.columnName)
				setSortDirection(args.action.direction)
			} else {
				setSortBy(null)
				setSortDirection(null)
			}

			setPage(1)

			return
		}

		if(args.action.requestType === 'filtering') {

			if(args.action.action === 'filter') {
				let updatedFilters = filterColumns.map(item => {
					
					if(item.field === args.action.currentFilterObject.field) {
						return { ...item, field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value }
					}

					return item
				})

				let filteredFilterColumns = updatedFilters.filter(item => item.field === args.action.currentFilterObject.field)
				if(filteredFilterColumns.length === 0) {
					updatedFilters.push({field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value})
				}

				if(updatedFilters.length > 0) {
					setFilterColumns(updatedFilters)
				} else {
					setFilterColumns(result => [...result, {field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value}])
				}
			}

			if(args.action.action === 'clearFilter') {
				const indexToRemove = filterColumns.findIndex(item => item.field === args.action.currentFilterObject.properties.field)
				let updatedFilters = filterColumns.filter((item, index) => index !== indexToRemove)
				setFilterColumns(updatedFilters)
			}

			setPage(1)

			return
		}
	}

	const getAd = (value) => {
		const headlines = value.headlines
		const headlinesArr = headlines.map(item => item.text)

		const descriptions = value.descriptions
		const descriptionsArr = descriptions.map(item => item.text)

		const slicedHeadlines = (headlinesArr.length > 2) ? headlinesArr.slice(0, 2) : headlinesArr
		let slicedDescriptions = (descriptionsArr.length > 1) ? descriptionsArr.slice(0, 1) : descriptionsArr

		return (
			<>
				<span>{slicedHeadlines.join(' | ')}</span><br/>
				<span>{slicedDescriptions.join('')}</span>
			</>
		)
	}

	const getCampaignId = (item) => item.campaign_id
	const getCampaignName = (item) => item.campaign_name

	const getAdgroupId = (item) => item.adgroup_id
	const getAdgroupName = (item) => item.adgroup_name

	const getCampaignAdgroups = (value) => {
		const account_id = default_account.id
		let campaign_ids = null
		if(value && Object.keys(value).length > 0) {
			campaign_ids = value.campaign_id
		}

		fetchCampaignAdgroups({ account_id, campaign_ids }).then(response => {
			if(response.data.success) {
				setAdgroups(response.data.adgroups)
			}
		})

	}

	const setSelectedCampaign = (value) => {
		if(!value) {
			setSelectedCampaigns([])
			
		} else {
			setSelectedCampaigns([value])
		}

		setSelectedAdgroups([])
		getCampaignAdgroups(value)
	}

	const applySelectedCampaigns = () => {

		dispatch({
			type: 'UPDATE_SELECTED_CAMPAIGNS',
			payload: { value: selectedCampaigns },
		})

		dispatch({
			type: 'UPDATE_SELECTED_ADGROUPS',
			payload: { value: selectedAdgroups },
		})

		setSelectedRating(null)
		setPage(1)
	}

	const getChartOptions = () => {

		const poor_ads = best_practices_stats['poor_ads']
		const avg_ads = best_practices_stats['avg_ads']
		const good_ads = best_practices_stats['good_ads']
		const excellent_ads = best_practices_stats['excellent_ads']
		// const pending_ads = best_practices_stats['pending_ads']

		return {

			animationEnabled: false,
			exportEnabled: false,
			title:{
				text: "Best Practices Ads"
			},
			data: [{
				type: "pie",
				indexLabel: "{label}: {y} Ads",		
				startAngle: -90,
				dataPoints: [
					{ y: poor_ads, label: "Poor" },
					{ y: avg_ads, label: "Average" },
					{ y: good_ads, label: "Good" },
					{ y: excellent_ads, label: "Excellent" },
					// { y: pending_ads, label: "Pending" },
				],
				click: function (e) {
					const filterRatingoption =  ratingOptions.filter(item => item.name === e.dataPoint.label)
					setSelectedRating(filterRatingoption.pop())
					setPage(1)
				},
			}]
		}
	}

	return (
		<>
			{!isPageLoading &&
				<>
					{Object.keys(best_practices_stats).length > 0 &&
						<Row className="mb-2">
							<Col md={12}>
								<CanvasJSChart options = { getChartOptions() } />
							</Col>
						</Row>
					}

					<Form>
						<Row>
							<Col md={4}>
								<Form.Group className="mb-4">
									<Select placeholder="All Campaigns" menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} defaultValue={selectedCampaigns} options={campaigns} getOptionLabel={getCampaignName} getOptionValue={getCampaignId} onChange={setSelectedCampaign} closeMenuOnSelect={true} isSearchable={true} isClearable={true} />
								</Form.Group>
							</Col>

							<Col md={4}>
								<Form.Group className="mb-4">
									<Select placeholder="All Ad Groups" menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isMulti defaultValue={selectedAdgroups} options={adgroups} getOptionLabel={getAdgroupName} getOptionValue={getAdgroupId} onChange={setSelectedAdgroups} closeMenuOnSelect={false} isSearchable={true} />
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-4">
									<Button variant="primary" onClick={applySelectedCampaigns}>Apply</Button>
								</Form.Group>
							</Col>
						</Row>
					</Form>
						
					<Row className="mb-3">
						<Col md={12}>
							{/* height={400} autoFit={true} enableVirtualization={true} enableColumnVirtualization={true} */}

							<GridComponent height={400} ref={grid => gridInstance = grid} allowFiltering={true} allowSorting={true} enableStickyHeader={true} frozenColumns={2} loadingIndicator={{ indicatorType: 'Shimmer' }} filterSettings={filterSettings} dataStateChange={dataStateChange}>
								<ColumnsDirective>
					            	<ColumnDirective field='campaign_name' width='420' headerText='Campaign Name' />
					            	<ColumnDirective field='adgroup_name' width='210' headerText='Ad Group Name' />
					            	<ColumnDirective allowSorting={false} headerText='Ad' width='500' template={getAd} />
					            	<ColumnDirective field='policy_issues' width='160' headerText='Policy Issues' />
					            	<ColumnDirective field='ad_strength' width='155' headerText='Ad Strength' />
					        	</ColumnsDirective>
					        	<Inject services={[Freeze, Sort, Filter]}/>
							</GridComponent>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<div className="text-center">
								<PaginationControl page={page} total={totalRows} changePage={(page) => setPage(page)} limit={10} ellipsis={1} />
							</div>
						</Col>
					</Row>
				</>
			}

			{isPageLoading &&
				<>
					<AssetSummarySkeleton />
				</>
			}
		</>
	)
}

export default memo(AdSummary)
