import axios from 'axios'

class AssetService {

	getAccountAssetSummary(params) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/assets/account_summary/`, {
				params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	getCampaignAssetSummary(params) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/assets/campaign_summary/`, {
				...params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	getAdGroupAssetSummary(params) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/assets/adgroup_summary/`, {
				...params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	getAdAssetSummary(params) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/assets/ad_summary/`, {
				...params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	fetchCampaignAdgroups(params) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/assets/get_campaign_adgroups/`, {
				params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

}
const assetService = new AssetService()

export default assetService