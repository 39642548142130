import { memo } from 'react'
import Card from 'react-bootstrap/Card'
import Placeholder from 'react-bootstrap/Placeholder'
import Form from 'react-bootstrap/Form'

const GoogleAuthCallbackSkeleton = (props) => {
	
	return (
		<>
			<Card>
				<Placeholder as={Card.Header} animation="glow">
					<Placeholder xs={3} />
				</Placeholder>
				
				<Placeholder as={Card.Body} animation="glow">
					
					<Placeholder as={Form} animation="glow">
						<Placeholder size="lg" xs={6} />
					</Placeholder>

					<Placeholder.Button variant="primary" className="mt-4" xs={4} />

				</Placeholder>
			</Card>
		</>
	)
}

export default memo(GoogleAuthCallbackSkeleton)