const initialState = {
	oauth_setting: {}

}

const googleAuthReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_OAUTH_SETTING':
			return {
				...state,
				oauth_setting: {...action.payload.oauth_setting},
			}

		default:
			return { ...state }

	}
}

export default googleAuthReducer