import { connect } from 'react-redux'
// import AdGroupSummary from 'pages/assets/ad_group_summary'
import AdGroupSummary from 'pages/assets/components/ad_group_summary'
import { getAdGroupAssetSummary, fetchCampaignAdgroups } from 'actions'

const mapStateToProps = (state) => {
   return {
      default_account: state.reducer.default_account,
      ad_group_summary_data: state.assetReducer.ad_group_summary_data,
      best_practices_stats: state.assetReducer.best_practices_stats,
      selected_campaigns: state.assetReducer.selected_campaigns,
      selected_adgroups: state.assetReducer.selected_adgroups,

   }
}

const mapActionsToProps = {
   getAdGroupAssetSummary,
   fetchCampaignAdgroups,
}

export default connect(mapStateToProps, mapActionsToProps)(AdGroupSummary)