import { connect } from 'react-redux'
// import AdSummary from 'pages/assets/ad_summary'
import AdSummary from 'pages/assets/components/ad_summary'
import { getAdAssetSummary, fetchCampaignAdgroups } from 'actions'

const mapStateToProps = (state) => {
   return {
      default_account: state.reducer.default_account,
      ad_summary_data: state.assetReducer.ad_summary_data,
      best_practices_stats: state.assetReducer.best_practices_stats,
      selected_campaigns: state.assetReducer.selected_campaigns,
      selected_adgroups: state.assetReducer.selected_adgroups,
   }
}

const mapActionsToProps = {
   getAdAssetSummary,
   fetchCampaignAdgroups,
}

export default connect(mapStateToProps, mapActionsToProps)(AdSummary)