import axios from 'axios'

class SearchTermService {

	getNegativeKeywords(params) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/searchterms/negative/`, {
				...params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

}
const searchTermService = new SearchTermService()

export default searchTermService