const initialState = {
	accounts: [],

}

const googleAccountsReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_GOOGLE_ACCOUNTS':
			return {
				...state,
				accounts: [ ...action.payload.accounts ],
			}

		case 'DELETE_GOOGLE_ACCOUNT':
			let newAccounts = state.accounts.filter(item => item.id !== action.payload.id)
			return {
				...state,
				accounts: [ ...newAccounts ],
			}

		default:
			return { ...state }

	}
}

export default googleAccountsReducer