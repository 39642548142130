import { connect } from 'react-redux'
import GoogleAuthCallback from 'pages/google_auth/callback'
import { fetchGoogleAccounts, addGoogleAccounts } from 'actions'

// const mapStateToProps = (state) => {
//    return {
//       ...state.googleAuthReducer,
//    };
// };

export default connect(null, { fetchGoogleAccounts, addGoogleAccounts})(GoogleAuthCallback)