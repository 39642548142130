import { connect } from 'react-redux'
import Header from 'layout/components/header'
import { fetchUser, submitLogout } from 'actions/auth'
const mapStateToProps = (state) => {
	return {
		authenticated: state.reducer.authenticated,
		user: state.reducer.user,
		accounts: state.reducer.accounts,
		default_account: state.reducer.default_account,
	}
}

export default connect(mapStateToProps, { fetchUser, submitLogout })(Header)