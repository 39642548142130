import { connect } from 'react-redux'
import GoogleAuth from 'pages/google_auth/accounts'
import { getGoogleAccounts, deleteGoogleAccount, makeDefaultGoogleAccount } from 'actions'

const mapStateToProps = (state) => {
   return {
      ...state.googleAccountsReducer,
   };
};

export default connect(mapStateToProps, { getGoogleAccounts, deleteGoogleAccount, makeDefaultGoogleAccount })(GoogleAuth)