import { memo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FaGoogle } from 'react-icons/fa'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const GoogleAuth = (props) => {

	const { oauth_setting, getAuthCredentials, generateGoogleAuthTokens } = props
	const [searchParams, ] = useSearchParams()
	const code = searchParams.get('code') || null
	const [tokens, setTokens] = useState(null)

	useEffect(() => {
		getAuthCredentials()
	}, [getAuthCredentials])

	useEffect( () => {
		if(!code) return

		// console.log(code)

		generateGoogleAuthTokens({ code }).then(response => {
			if(response.data.success) {
				setTokens(response.data.tokens)
			}
		}).catch(error => console.log(error))

	}, [code, generateGoogleAuthTokens])

	const loginWithGoogle = () => window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&prompt=consent&client_id=${oauth_setting.client_id}&redirect_uri=${oauth_setting.redirect_uri}&scope=${oauth_setting.scope}`
	
	return (
		<>
			<Layout>
				<Row>
					<Col md={12}>
						<Card>
							<Card.Header>Google Ads Authentication</Card.Header>

							<Card.Body>
								<div className="text-center">
									<Button variant="primary" onClick={loginWithGoogle} disabled={!Object.keys(oauth_setting).length}><FaGoogle size={16} /> &nbsp; Signin With Google</Button>
								</div>
							</Card.Body>
						</Card>

						{tokens &&
							<div>
								<h4>Google Oauth Access Tokens</h4>
								<pre>{JSON.stringify(tokens, null, 2)}</pre>
							</div>
						}
					</Col>
				</Row>
			</Layout>
		</>
	)
}

export default memo(GoogleAuth)