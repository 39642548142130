import { memo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import AccountSummary from "containers/accountSummaryContainer"
import CampaignSummary from 'containers/campaignSummaryContainer'
import AdGroupSummary from 'containers/adGroupSummaryContainer'
import AdSummary from 'containers/adSummaryContainer'

const Assets = (props) => {

	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState('account')

	const showTab = useCallback((tab, selected_campaigns = [], selected_adgroups = []) => {
		setActiveTab(tab)

		dispatch({
			type: 'UPDATE_SELECTED_CAMPAIGNS',
			payload: { value: selected_campaigns },
		})

		dispatch({
			type: 'UPDATE_SELECTED_ADGROUPS',
			payload: { value: selected_adgroups },
		})

	}, [setActiveTab, dispatch])
	
	return (
		<>
			<Layout>
				<Row>
					<Col md={12}>
						<Card>
							<Card.Header>Assets Summary</Card.Header>

							<Card.Body>
								<Tabs defaultActiveKey={activeTab} activeKey={activeTab} variant="underline" className="mb-3" justify onSelect={(eventKey) => showTab(eventKey)}>
									<Tab eventKey="account" title="Account">
										{activeTab === 'account' &&
											<AccountSummary showTab={showTab}  />
										}
									</Tab>

									<Tab eventKey="campaign" title="Campaign">
										{activeTab === 'campaign' &&
											<CampaignSummary showTab={showTab}  />
										}
									</Tab>

									<Tab eventKey="ad_group" title="Ad Group">
										{activeTab === 'ad_group' &&
											<AdGroupSummary showTab={showTab}  />
										}
									</Tab>

									<Tab eventKey="ad" title="Ad">
										{activeTab === 'ad' &&
											<AdSummary  />
										}
									</Tab>
								</Tabs>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				
			</Layout>
		</>
	)
}

export default memo(Assets)