import { memo } from 'react'
import Card from 'react-bootstrap/Card'
import Placeholder from 'react-bootstrap/Placeholder'

const NegativeKeywordsSkeleton = (props) => {

	return (
		<>
			<Placeholder.Button variant="secondary" className="mb-2" xs={2} />
						
			<Card>
				<Placeholder as={Card.Header} animation="glow">
					<Placeholder xs={3} />
				</Placeholder>
				
				<Placeholder as={Card.Body} animation="glow">
					{Array.from({ length: 10 }, (_, index) =>
						<Placeholder key={index} as="p" animation="glow">
							<Placeholder xs={12} />
						</Placeholder>
					)}
				</Placeholder>
			</Card>
		</>
	)
}

export default memo(NegativeKeywordsSkeleton)