import { connect } from 'react-redux'
import Assets from 'pages/assets'
// import { getAccountAssetSummary, getCampaignAssetSummary, getAdGroupAssetSummary, getAdAssetSummary } from 'actions'

/*const mapStateToProps = (state) => {
   return {
      ...state.reducer.assetReducer,
   };
}

const mapActionsToProps = {
   getAccountAssetSummary,
   getCampaignAssetSummary,
   getAdGroupAssetSummary,
   getAdAssetSummary,

}*/

export default connect(null, null)(Assets)