import { connect } from 'react-redux'
import GoogleAuth from 'pages/google_auth'
import { getAuthCredentials, generateGoogleAuthTokens } from 'actions'

const mapStateToProps = (state) => {
   return {
      ...state.googleAuthReducer,
   };
};

export default connect(mapStateToProps, { getAuthCredentials, generateGoogleAuthTokens })(GoogleAuth)