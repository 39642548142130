import { memo, useState, useEffect } from 'react'
import Sidebar from 'react-bootstrap-sidebar-menu'
import { useNavigate, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometer, faPieChart, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

const CustomSidebar = (props) => {

	const { authenticated } = props
	const navigate = useNavigate()
	const location = useLocation()
	const [searchTermsMenuExpanded, setSearchTermsMenuExpanded] = useState(false)
	const [accountsMenuExpanded, setAccountsMenuExpanded] = useState(false)
	
	useEffect( () => {
		const currentRoutePath = location.pathname
		const searchTermsPaths = ['/searchterms/negative']
		const accountsMgmtPaths = ['/google/accounts']

		if(searchTermsPaths.includes(currentRoutePath)) {
			setSearchTermsMenuExpanded(true)	
		}

		if(accountsMgmtPaths.includes(currentRoutePath)) {
			setAccountsMenuExpanded(true)	
		}

	}, [location.pathname])

	return (
		<>
			{authenticated &&	
				<Sidebar variant="light" bg="light" expand="sm">
		      		<Sidebar.Collapse>
		      		
		      			<Sidebar.Header>
		      				<Sidebar.Brand>Search AI</Sidebar.Brand>
		      				<Sidebar.Toggle />
		      			</Sidebar.Header>

		      			<Sidebar.Body>
		      				<Sidebar.Nav>
		      					<Sidebar.Nav.Link onSelect={() => navigate('/dashboard') } active={location.pathname === '/dashboard'} eventKey='dashboard'>
		      						<Sidebar.Nav.Icon><FontAwesomeIcon icon={faTachometer} /></Sidebar.Nav.Icon>
				                	<Sidebar.Nav.Title>Dashboard</Sidebar.Nav.Title>
				              	</Sidebar.Nav.Link>

				              	<Sidebar.Nav.Link onSelect={() => navigate('/assets') } active={location.pathname === '/assets'} eventKey='ads_grader_report'>
				              		<Sidebar.Nav.Icon><FontAwesomeIcon icon={faPieChart} /></Sidebar.Nav.Icon>
				                	<Sidebar.Nav.Title>Ads Grader Report</Sidebar.Nav.Title>
				              	</Sidebar.Nav.Link>

				              	<Sidebar.Sub eventKey={0} onToggle={() => {setSearchTermsMenuExpanded(!searchTermsMenuExpanded)}} expanded={searchTermsMenuExpanded}>
				              		<Sidebar.Sub.Toggle>
				              			<Sidebar.Nav.Icon />
				              			<Sidebar.Nav.Title>Search Terms</Sidebar.Nav.Title>
				              		</Sidebar.Sub.Toggle>

				              		<Sidebar.Sub.Collapse>
				              			<Sidebar.Nav>
				              				<Sidebar.Nav.Link onSelect={() => navigate('/searchterms/negative') } active={location.pathname === '/searchterms/negative'} eventKey='negative_search_term_keywords'>
				              					<Sidebar.Nav.Icon><FontAwesomeIcon icon={faThLarge} /></Sidebar.Nav.Icon>
				              					<Sidebar.Nav.Title>Negative Keywords</Sidebar.Nav.Title>
				              				</Sidebar.Nav.Link>
				              			</Sidebar.Nav>
				              		</Sidebar.Sub.Collapse>
				              	</Sidebar.Sub>

				              	<Sidebar.Sub eventKey={1} onToggle={() => {setAccountsMenuExpanded(!accountsMenuExpanded)}} expanded={accountsMenuExpanded}>
				              		<Sidebar.Sub.Toggle>
				              			<Sidebar.Nav.Icon />
				              			<Sidebar.Nav.Title>Accounts Management</Sidebar.Nav.Title>
				              		</Sidebar.Sub.Toggle>

				              		<Sidebar.Sub.Collapse>
				              			<Sidebar.Nav>
				              				<Sidebar.Nav.Link onSelect={() => navigate('/google/accounts') } active={location.pathname === '/google/accounts'} eventKey='google_accounts'>
				              					<Sidebar.Nav.Icon><FontAwesomeIcon icon={faGoogle} /></Sidebar.Nav.Icon>
				              					<Sidebar.Nav.Title>Google</Sidebar.Nav.Title>
				              				</Sidebar.Nav.Link>
				              			</Sidebar.Nav>
				              		</Sidebar.Sub.Collapse>
				              	</Sidebar.Sub>
		      				</Sidebar.Nav>
		      			</Sidebar.Body>

		      		</Sidebar.Collapse>
		      	</Sidebar>
	      	}
		</>
	)
}

export default memo(CustomSidebar)