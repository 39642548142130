import AssetService from 'services/assetService'

const getAccountAssetSummary = (params) => async(dispatch) => {
	try {
      
      const response = await AssetService.getAccountAssetSummary(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_ACCOUNT_SUMMARY',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const getCampaignAssetSummary = (params) => async(dispatch) => {
   try {
      
      const response = await AssetService.getCampaignAssetSummary(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_CAMPAIGN_SUMMARY',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const getAdGroupAssetSummary = (params) => async(dispatch) => {
   try {
      
      const response = await AssetService.getAdGroupAssetSummary(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_AD_GROUP_SUMMARY',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const getAdAssetSummary = (params) => async(dispatch) => {
   try {
      
      const response = await AssetService.getAdAssetSummary(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_AD_SUMMARY',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const fetchCampaignAdgroups = (params) => async(dispatch) => {
   try {
      
      const response = await AssetService.fetchCampaignAdgroups(params)
      return response

   } catch(err) {
      console.log(err)
   }  
}

export {
	getAccountAssetSummary,
   getCampaignAssetSummary,
   getAdGroupAssetSummary,
   getAdAssetSummary,
   fetchCampaignAdgroups,


}
