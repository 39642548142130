import axios from 'axios'

class AuthService {
	login(payload) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/login/`, {...payload}).then(response => {
				resolve(response)
			}).catch(error => {
				// console.log(error)
				reject(error)
			})
		})
	}
	
	logout() {
	 	const token = localStorage.getItem('token')
		
		return new Promise((resolve, reject) => {
			axios.get(`/api/logout/`, {
				headers: {
					Authorization: `Token ${token}`,
				}
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
	
	user() {
	 	const token = localStorage.getItem('token')
		
		return new Promise((resolve, reject) => {
			axios.get(`/api/user/`, {
				headers: {
					Authorization: `Token ${token}`,
				}
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}
const authService = new AuthService()

export default authService