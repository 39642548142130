import { memo, useState } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { useAuth } from "context"
import { useNavigate } from "react-router-dom"

const Login = (props) => {

	const { submitLogin } = props
	const { setAuthToken } = useAuth()
	const navigate = useNavigate()
	const [errorMessage, setErrorMessage] = useState('')
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const handleInput = (ev) => {
		if(ev.target.name === 'username') {
			setUsername(ev.target.value)
		}

		if(ev.target.name === 'password') {
			setPassword(ev.target.value)
		}
	}

	const handleSubmit = () => {
		setErrorMessage('')

		submitLogin({username, password}).then(response => {
			if(response.data.token) {
				setAuthToken(response.data.token)
				navigate('/dashboard')
			}

		}).catch(error => {
			if(error.response.data.non_field_errors) {
				setErrorMessage(error.response.data.non_field_errors[0])
			} else {
				setErrorMessage('Unable to log in with provided credentials.')
			}
		})
	}

	return (
		<>
			<Layout>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						{errorMessage &&
							<Alert variant="danger">
								{ errorMessage }
							</Alert>
						}
						<Card>
							<Card.Header>Login</Card.Header>
							<Card.Body>
								<Form>
									<Form.Group className="mb-3">
										<Form.Label>Email</Form.Label>
    									<Form.Control type="email" name="username" defaultValue={username} onChange={handleInput} placeholder="Enter your email" />
  									</Form.Group>

  									<Form.Group className="mb-3">
										<Form.Label>Password</Form.Label>
    									<Form.Control type="Password" name="password" defaultValue={password} onChange={handleInput} placeholder="Enter your password" />
  									</Form.Group>

  									<Button variant="primary" onClick={handleSubmit}>Login</Button>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Layout>
		</>
	)
}

export default memo(Login)