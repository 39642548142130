import { Fragment } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { routes, PrivateRoute } from "./routes"
import { useAuth } from "context"

const App = () => {

    const { isLogin } = useAuth()

    return (
      <>
        <Routes>
          {routes.map((item) =>
            item?.protected ? (
              <Fragment key={item.path}>
                <Route
                  path={item.path}
                  element={<PrivateRoute>{item.element}</PrivateRoute>}
                  key={item.path}
                />
              </Fragment>
            ) : item?.auth && isLogin ? (
              <Route
                path={item.path}
                element={<Navigate replace to="/dashboard" />}
                key={item.path}
              />
            ) : (
              <Route path={item.path} element={item.element} key={item.path} />
            )
          )}
        </Routes>
      </>
    )
}

export default App