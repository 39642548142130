import { memo, useEffect, useState } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useNavigate } from "react-router-dom"
import GoogleAccountsSkeleton from './components/skeleton/accounts_skeleton'
import Alert from 'react-bootstrap/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const GoogleAccounts = (props) => {

	const  { accounts, getGoogleAccounts, deleteGoogleAccount, makeDefaultGoogleAccount } = props
	const [isPageLoading, setIsPageLoading] = useState(true)
	const navigate = useNavigate()

	useEffect(() => {
		getGoogleAccounts().then(response => setIsPageLoading(false)).catch(error => setIsPageLoading(false))
	}, [getGoogleAccounts])

	const deleteAccount = (item) => {
		var confirm_delete = window.confirm("Are you sure you want to delete?")
		if(confirm_delete) {
			deleteGoogleAccount(item.id).then(response => {
				if(response.data.success) {
					window.location.href = '/google/accounts'
				}
			})
		}
	}

	const makeDefaultAccount = (item) => {
		makeDefaultGoogleAccount(item.id).then(response => {
			if(response.data.success) {
				window.location.href = '/google/accounts'
			}
		})
	}

	const showIcons = (item) => {
		return (
			<>
				{!item.is_default &&
					<>
						<a href="/#" title="Make Default Account" onClick={(ev) => {ev.preventDefault(); makeDefaultAccount(item)}}><FontAwesomeIcon icon={faExclamationCircle} /></a> &nbsp;
					</>
				}

				{item.is_default &&
					<>
						<a href="/#" onClick={(ev) => {ev.preventDefault()}}><FontAwesomeIcon icon={faExclamationCircle} color={'grey'} style={{opacity: 0.6, cursor: 'not-allowed'}} /></a> &nbsp;
					</>
				}
				<a href="/#" onClick={(ev) => {ev.preventDefault(); deleteAccount(item)}}><FontAwesomeIcon icon={faTrash} /></a>
			</>
		)
	}

	
	return (
		<>
			<Layout>
				<Row>
					<Col md={12}>
						
						{!isPageLoading &&
							<>
								<Button variant="primary" type="button" className="mb-2" onClick={() => navigate('/google/login')}>Add Account</Button>
						
								<Card>
									<Card.Header>Google Accounts</Card.Header>
									<Card.Body>
										
										{accounts.length > 0 &&
											<Table striped bordered responsive>
												<thead>
													<tr>
														<th>Account Name</th>
														<th>Account ID</th>
														<th>Manager Account</th>
														<th className="text-center">Action</th>
													</tr>
												</thead>

												<tbody>
													{accounts.map((item, index) => <tr key={index}><td>{item.ad_account_name}</td><td>{item.ad_account_id}</td><td>{item.mcc_account_name}</td><td className="text-center">{ showIcons(item) }</td></tr>)}
												</tbody>
											</Table>
										}

										{accounts.length === 0 &&
											<Alert variant='danger'>
												No accounts added
											</Alert>
										}
										

									</Card.Body>
								</Card>
							</>
						}

						{isPageLoading &&
							<GoogleAccountsSkeleton />
						}
					</Col>
				</Row>
			</Layout>
		</>
	)
}

export default memo(GoogleAccounts)