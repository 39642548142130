import { memo } from 'react'
import Header from "containers/headerContainer"
import Sidebar from "containers/sidebarContainer"
import Main from "./components/main"

const Layout = (props) => {

	const { children } = props

	return (
		<>
			<div className="main-wrapper">
				
				<Header />

				<Sidebar />

		      	<Main>
		      		{ children }
		      	</Main>
			</div>
		</>
	)
}

export default memo(Layout)